<template>
  <div style="width:100%;height:100%">
    <agc-svg name="loading" size="100" v-if="savingDialogVisible" class="loading" />
    <div class="confirm-header" v-else>
      <agc-page-header
        :show-back="true"
        @back="saves('Form',1)"
        :title="$t('ctp.translationServices')"
        :description="$t('ctp.translationDescription')"
      >
        <el-link type="primary" target="_blank" :href="helpLink">
          {{ $t('ctp.helpDocument') }}
        </el-link>
      </agc-page-header>
      <div class="confirm-order-main">
        <div class="supplier-title supplier-title-font">
          {{ $t('ctp.selectSupplier') }}
        </div>
        <div class="zone-box">
          <div v-show="iScountryCode">
            <agc-icon name="warning" size="14" color="rgb(255, 189, 0)" style="margin-right:8px" />
            <span>{{ $t('ctp.siteTips') }}</span>
          </div>
        </div>
        <div>
          <table class="supplier-up-table supplier-table">
            <tr class="text-left">
              <td width="3%" />
              <td class="text-center" width="15%">
                <span>{{ $t('ctp.supplier') }}</span>
              </td>
              <td class="text-center" width="15%">
                <span>{{ $t('ctp.supplieIntroduction') }}</span>
              </td>
            </tr>
            <template v-for="(item,index) in supplierListOne">
              <tr class="first-tr" :key="index">
                <td class="text-left">
                  <label
                    class="el-radio"
                    :class="item.spId === supplier.spId ? 'is-checked' : 'is-focus'"
                  >
                    <span
                      class="el-radio__input"
                      :class="item.spId === supplier.spId ? 'is-checked' : ''"
                    >
                      <span class="el-radio__inner" />
                      <input
                        type="radio"
                        name="b"
                        :value="item"
                        v-model="supplier"
                        class="el-checkbox__original"
                        @click.stop="SwitchSupplier(index)"
                      >
                    </span>
                  </label>
                </td>
                <td class="text-center">
                  {{ item.spNameValue ? item.spNameValue : "--" }}
                </td>
                <td class="text-center">
                  <el-link
                    type="primary"
                    target="_blank"
                    :href="item.webSite"
                  >
                    {{ $t('ctp.description') }}
                  </el-link>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <div class="supplier-div-item">
          <div class="label-box">
            <span class="lable-color">*</span>
            <span>{{ $t('ctp.contactInformation') }}{{ $t("ctp.colon") }}</span>
          </div>
          <span class="expect-time">{{ $t('ctp.tips.contactInformation') }}</span>
          <br>
          <el-form :model="Form" :rules="rules" ref="Form">
            <el-form-item prop="devEmail">
              <el-input
                maxlength="80"
                show-word-limit="true"
                v-model="Form.devEmail"
                class="devemail-input input-text"
                :placeholder="$t('ctp.contactInformationPlh')"
                autocomplete="off"
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="supplier-div-less-item">
          <div class="label-box">
            <span>{{ $t('ctp.expectedTime') }}{{ $t("ctp.colon") }}</span>
          </div>
          <span class="expect-time">{{ $t('ctp.tips.expectedTime') }}</span>
          <br>
          <el-input-number
            v-model="expectDeliveryDay"
            @change="handleChange"
            class="input-text"
            :step="1"
            step-strictly
            :min="minExpectDay"
          />
        </div>
        <div class="supplier-div-item">
          <div class="splitting-line" />
          <div class="supplier-title splitting-line-div">
            {{ $t('ctp.orderTittle') }}
            <el-tooltip class="item" effect="light" placement="right">
              <div slot="content" class="maxWidthSty">
                {{ $t('ctp.tips.orderTittle') }}
              </div>
              <agc-icon name="question" color="#c5c5c5" size="18" />
            </el-tooltip>
          </div>
          <div class="label-box">
            {{ $t('ctp.orderPreview') }}
          </div>
          <table class="supplier-lang-table">
            <tr class="title-weight">
              <td>{{ $t('ctp.orderNum') }}</td>
              <td>{{ $t('ctp.orderContent') }}</td>
              <td>{{ $t('ctp.originLanguage') }}</td>
              <td>{{ $t('ctp.targetLanguage') }}</td>
              <td>{{ $t('ctp.startingPrice', {currency: supplier.currency || '--'}) }}</td>
              <td>{{ $t('ctp.singWordUnitPrice', {currency: supplier.currency || '--'}) }}</td>
              <td>
                {{ $t('ctp.totalWords') }}
                <el-tooltip class="item" effect="light" placement="top-end">
                  <div slot="content">
                    {{ $t('ctp.tips.totalWords') }}
                  </div>
                  <agc-icon name="question" color="#c5c5c5" size="18" class="question-weight" />
                </el-tooltip>
              </td>
              <td>{{ $t('ctp.singleLangTotal', {currency: supplier.currency || '--'}) }}</td>
            </tr>
            <tr v-if="loading" style="height:200px">
              <td colspan="8" class="tr-main">
                <agc-svg name="loading" size="100" />
                <p style="margin-top:15px">
                  {{ $t('ctp.tips.calculatePrice') }}
                </p>
              </td>
            </tr>
            <tr v-else-if="empty" style="height:200px">
              <td colspan="8" class="tr-main">
                <div slot="empty">
                  <agc-empty height="200px" />
                </div>
              </td>
            </tr>
            <tbody v-else>
              <div class="couponsMoney-div">
                <el-tooltip class="item" effect="light" placement="bottom-end" :disabled="availableCouponList.length > 0">
                  <div slot="content">
                    {{ $t('ctp.noAvailable') }}
                  </div>
                  <i class="el-icon-arrow-right" :class="availableCouponList.length > 0 ? 'arrow-right' : 'notclick'" @click="openCouponsDialog" />
                </el-tooltip>
              </div>
              <div class="vouchersMoney-div">
                <el-tooltip class="item" effect="light" placement="bottom-end" :disabled="vouchersList.length > 0">
                  <div slot="content">
                    {{ $t('ctp.noAvailable') }}
                  </div>
                  <i class="el-icon-arrow-right" :class="vouchersList.length > 0 ? 'arrow-right' : 'notclick'" @click="openVouchersDialog" />
                </el-tooltip>
              </div>
              <tr v-for="(lang,index) in supplier.lang" :key="index" class="second-tr">
                <td :rowspan="supplier.lang.length" v-if="index === 0">
                  {{ result.taskId }}
                </td>
                <td :rowspan="supplier.lang.length" v-if="index === 0">
                  <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
                    <div slot="content">
                      {{ showTitle }}
                    </div>
                    <span v-if="result.application === 'true'">
                      {{ showTitle.length > 15 ? showTitle.substring(0,15) + '...' : showTitle }}
                      <br>
                    </span>
                  </el-tooltip>
                  <span
                    v-if="result.sourceFileName && result.sourceFileName !== ''"
                    :title="result.sourceFileName"
                  >
                    {{ result.sourceFileName.length > 15 ?
                      result.sourceFileName.substring(0,15) + '...' : result.sourceFileName }}
                  </span>
                </td>
                <td
                  :rowspan="supplier.lang.length"
                  v-if="index === 0"
                >
                  {{ supplier.sourceLanguageName }}
                </td>
                <td>{{ lang.targetLanguageName }}</td>

                <td>{{ lang.prePrice ? Number(lang.prePrice).toFixed(2) : '0.00' }}</td>
                <td>{{ lang.pre ? Number(lang.pre).toFixed(2) : "" }}</td>
                <td>{{ lang.totalWords ? Math.ceil(Number(lang.totalWords)) : '--' }}</td>
                <td>{{ lang ? Number(lang.order).toFixed(2) : "" }}</td>
              </tr>
              <tr v-if="noTargetLan[supplier.spId]" class="noTargetLan">
                <td :colspan="8" class="noTarget">
                  <span>{{ $t('ctp.tips.notSupportedLang',{num: notSupportTargetLangLength}) }}</span>
                  <br>
                  <span>{{ noTargetLan[supplier.spId] }}</span>
                </td>
              </tr>
              <tr class="priceTable">
                <td :colspan="8">
                  <!-- 总价 -->
                  <div class="priceTable-div-number">
                    <div>
                      {{ Number(supplier.order).toFixed(2) }}
                    </div>
                    <!-- 折扣优惠 -->
                    <div v-if="supplier.discount >= 0">
                      -{{ Number(supplier.discount).toFixed(2) }}
                    </div>
                    <div v-else>
                      {{ (Number(supplier.total) - Number(supplier.order)).toFixed(2) }}
                    </div>
                    <!-- 满减券 -->
                    <div>
                      -{{ couponsMoney && Number(supplier.discount) !== -1
                        ? Number(couponsMoney.remainingAmount).toFixed(2) : '0.00' }}
                    </div>
                    <!-- 代金券 -->
                    <div>
                      -{{ vouchersMoney && Number(supplier.discount) !== -1
                        ? Number(vouchersMoney).toFixed(2) : '0.00' }}
                    </div>
                    <!-- 实际付款 -->
                    <div class="totalPrice">
                      {{ Number(supplier.total).toFixed(2) }}
                    </div>
                  </div>
                  <!-- 总价 -->
                  <div class="priceTable-div">
                    <div class="priceLabel">
                      {{ $t('ctp.totalPrice', {currency: supplier.currency ? supplier.currency : '--'}) }}
                      {{ $t('ctp.colon') }}
                    </div>
                    <!-- 折扣优惠 -->
                    <div class="priceLabel">
                      {{ $t('ctp.preference', {currency: supplier.currency ? supplier.currency : '--'}) }}
                      <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end">
                        <div slot="content">
                          {{ $t('ctp.tips.preference') }}
                        </div>
                        <agc-icon name="question" color="#c5c5c5" size="18" />
                      </el-tooltip>
                      {{ $t('ctp.colon') }}
                    </div>
                    <!-- 满减券 -->
                    <div class="priceLabel">
                      {{ $t('ctp.rewardsCoupon', {currency: supplier.currency ? supplier.currency : '--'}) }}
                      <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end">
                        <div slot="content">
                          {{ $t('ctp.couponsTips') }}
                        </div>
                        <agc-icon name="question" color="#c5c5c5" size="18" />
                      </el-tooltip>
                      {{ $t('ctp.colon') }}
                    </div>
                    <!-- 代金券 -->
                    <div class="priceLabel">
                      {{ $t('ctp.vouchers', {currency: supplier.currency ? supplier.currency : '--'}) }}
                      <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end">
                        <div slot="content">
                          {{ $t('ctp.vouchersTips') }}
                        </div>
                        <agc-icon name="question" color="#c5c5c5" size="18" />
                      </el-tooltip>
                      {{ $t('ctp.colon') }}
                    </div>
                    <!-- 实际付款 -->
                    <div class="priceLabel">
                      {{ $t('ctp.actualPrice', {currency: supplier.currency ? supplier.currency : '--'}) }}
                      {{ $t('ctp.colon') }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="supplier-div-less-item">
          <div class="label-box">
            <span>{{ $t('ctp.payMethod') }}{{ $t("ctp.colon") }}</span>
          </div>
          <span class="pay-method">
            <el-radio-group v-model="payMethod">
              <el-radio :label="2" v-show="teamSiteId === '1'">{{ $t("ctp.thirdPayment") }}</el-radio>
              <el-radio :label="1" v-show="userType === '2'">{{ $t("ctp.balancePayment") }}</el-radio>
            </el-radio-group>
            <span class="balance-span">
              <agc-translation :value="$t('ctp.viewBalance')" :tags="refreshTags" />
            </span>
            <div class="currencyTipsShow" v-show="currencyTipsShow">
              <agc-icon name="warning" size="14" style="margin-right:8px" />
              <agc-translation
                style="display:inline-block"
                :value="$t('ctp.queryCurrency.msgContent')"
                :tags="{link: {href: linkUrl, target: 'blank'}}"
              />
            </div>
          </span>
        </div>
        <div class="supplier-div-less-item">
          <div class="label-box">
            <span>{{ $t('ctp.orderRemarks') }}{{ $t("ctp.colon") }}</span>
          </div>
          <el-input
            type="textarea"
            maxlength="80"
            :rows="4"
            show-word-limit="true"
            v-model="remarksForSp"
            class="textarea-style expect-time"
            :placeholder="$t('ctp.orderRemarksPlh')"
          />
        </div>

        <div class="gray-line" />

        <div>
          <span class="notice-before-save">{{ $t('ctp.tips.Privacy1') }}</span>
          <span class="notice-before-save">{{ $t('ctp.tips.Privacy2') }}</span>
        </div>
        <div class="supplierprivacy-div">
          <el-checkbox v-model="agreePrivacy" @change="isAgree">
            <i18n path="ctp.authorize" tag="a">
              <a
                :href="supplier.agreementUrl"
                class="authorize"
                type="primary"
                target="_blank"
              >{{ $t("ctp.contentPrivacy") }}</a>
            </i18n>
          </el-checkbox>
          <div v-show="noAgree">
            <agc-icon name="warning" size="9" color="#fa2a2d" />
            <span class="no-supplierprivacy-font">{{ $t("ctp.warn.supplierPrivacy") }}</span>
          </div>
        </div>

        <div>
          <el-button @click="cancel">
            {{ $t('ctp.previous') }}
          </el-button>
          <el-button type="primary" @click="saves('Form',0)">
            {{ $t('ctp.save') }}
          </el-button>
          <el-button
            :disabled="subBtnDisabled"
            type="primary"
            @click="submitTask('Form')"
          >
            {{ $t('ctp.submit') }}
          </el-button>
        </div>
      </div>
      <!--支付弹窗-->
      <agc-dialog
        :title="$t('ctp.payFor')"
        :visible.sync="conformDialogVisible"
        width="660px"
        @close="cancelPayment"
      >
        <div>
          <ul class="pay-ul">
            <li>
              <div class="order-time">
                <agc-icon name="question" size="16" color="#007DFF" />
                {{ $t('ctp.orderExpirationTime',{ordertime: ordertime}) }}
              </div>
            </li>
            <li>
              <div class="label-box">
                <span>{{ $t('ctp.payMethod') }}{{ $t("ctp.colon") }}</span>
              </div>
              <span class="pay-method">
                <el-radio-group v-model="payMethod" @change="changePayMethod">
                  <el-radio :label="2" v-show="teamSiteId === '1'">{{ $t("ctp.thirdPayment") }}</el-radio>
                  <el-radio :label="1" v-show="userType === '2'">{{ $t("ctp.balancePayment") }}</el-radio>
                </el-radio-group>
              </span>
            </li>
            <li>
              <span>{{ $t('ctp.orderNum') }}{{ $t('ctp.colon') }}</span>
              <div class="taskReplyList">
                <div class="taskReplyList-div">
                  <span>{{ result.taskId }}</span>
                </div>
              </div>
            </li>
            <li>
              <span class="pay-money-span">
                <span
                  class="pay-money"
                >{{ $t('ctp.payMoney', {currency: supplier.currency || '--'}) }}</span>
                <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end">
                  <div slot="content">{{ $t('ctp.tips.preference') }}</div>
                  <agc-icon name="question" color="#c5c5c5" size="18" />
                </el-tooltip>
              </span>
              <span
                class="total-color"
              >{{ totalPrice }}</span>
            </li>
            <li>
              <div class="qrcode-vue" v-show="payMethod === 2">
                <agc-svg name="loading" size="64" v-if="qRLoading" />
                <div id="downloadQr" v-else>
                  <QrcodeVue :value="qrValue" :size="size" level="H" class="qr-bottom" />
                  <div v-if="qrCodeExpirationTime">
                    <agc-translation
                      :value="$t('ctp.qrWillExpire',{minute: minutesCount, second: secondsCount})"
                      :tags="qcTimeTags"
                    />
                  </div>
                  <div v-else>
                    <agc-translation :value="$t('ctp.refreshQr')" :tags="refreshTags" />
                  </div>
                </div>
                <div class="pay-tips">
                  {{ $t('ctp.payTips') }}
                </div>
              </div>
            </li>
            <li>
              <div class="currency-tips" v-show="currencyTipsShow">
                <agc-icon name="warning" size="14" style="margin-right:8px" />
                <agc-translation
                  :value="$t('ctp.queryCurrency.msgContent')"
                  style="display:inline"
                  :tags="{link: {href: linkUrl, target: '_blank'}}"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="pay-dialog">
          <el-button
            type="primary"
            @click="pay"
            :disabled="payBtnDisabled"
            class="pay-for"
            v-show="payMethod === 1"
          >
            {{ $t('ctp.payFor') }}
          </el-button>
          <el-button @click="cancelPayment" class="pay-later">
            {{ $t('ctp.payLater') }}
          </el-button>
        </div>
      </agc-dialog>
      <agc-dialog :title="$t('ctp.paying')" :visible.sync="payingDialogVisible" width="480px">
        <div class="paying-dialog">
          <span>{{ $t('ctp.payingWait') }}</span>
        </div>
      </agc-dialog>
      <recharge-dialog ref="rechargeDialogView" />
      <agc-dialog :title="$t('ctp.tips')" :visible.sync="saveOrder" width="420px">
        <div>
          <span>{{ $t("ctp.tips.confirmSaveOrder") }}</span>
        </div>
        <div class="save-order">
          <span slot="footer">
            <el-button type="primary" @click="saveConfirm">{{ $t("ctp.ok") }}</el-button>
            <el-button @click="handleBack">{{ $t("ctp.cancel") }}</el-button>
          </span>
        </div>
      </agc-dialog>
      <agc-dialog :title="$t('ctp.tips')" :visible.sync="payTipsVisible" width="420px">
        <agc-translation :value="payTip" :tags="payTipTags" />
        <span slot="footer">
          <el-button type="primary" @click="payTipsVisible = false">{{ $t('ctp.close') }}</el-button>
        </span>
      </agc-dialog>
      <!-- 满减券弹窗 -->
      <CouponsDialog
        v-if="couponsVisible"
        :coupons-list="availableCouponList"
        :un-available-list="unAvailableCouponList"
        @selectCoupon="selectCoupon"
        @hideDialog="hideCouponsDialog"
      />
      <!-- 代金券弹窗 -->
      <VouchersDialog
        v-if="dialogVisible"
        :vouchers-list="vouchersList"
        @selectVoucher="selectVoucher"
        @hideDialog="hideDialog"
      />
    </div>
  </div>
</template>

<script>
import rechargeDialog from './recharge-dialog.vue';
import { getUserId, getTeamId } from '@appstore/agc-utils';
import {
  PAYGUIDE_TAGS,
  RECHARGELINK,
  HELPLINK,
  LANGOBJ,
  MENUIDOBJ,
  VOUCHER_TYPE,
  COUPON_TYPE
} from '../../constant/index.js';
import { floatToFixed, floatSub, floatAdd } from '../../util/priceProcessing.js';
import CouponsDialog from './coupons-dialog.vue';
import VouchersDialog from './vouchers-dialog.vue';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import { getConfigs } from '../../api/sp.js';
import { timestampToDate } from '../../util/common.js';
import {
  interactiveHaLog,
  interactiveHaLogLanguage
} from '../../util/interactiveHaLog.js';
export default {
  name: 'Confirmorder',
  components: {
    rechargeDialog,
    QrcodeVue,
    CouponsDialog,
    VouchersDialog
  },
  data() {
    return {
      Form: {
        devEmail: ''
      },
      agreePrivacy: false,
      noAgree: false,
      noTargetLan: {},
      payingDialogVisible: false,
      savingDialogVisible: false,
      payTipsVisible: false,
      supplierList: [],
      supplierListOne: [],
      total: 0,
      remarksForSp: '', // 备注信息
      supplier: {}, // 选中的供应商
      save00: false,
      userId: getUserId(),
      teamId: getTeamId(),
      actualPaymentAmount: '', // 优惠后价格
      spUid: '',
      noRepClick: true, // 避免重复点击支付按钮
      result: {},
      showTitle: '',
      expectDeliveryDay: null,
      expectDeliveryDayArr: [],
      timeout: null,
      conformDialogVisible: false,
      loading: true,
      flag: false,
      rules: {
        devEmail: [
          {
            required: true,
            message: this.$t('ctp.contactInformationPlh'),
            trigger: 'blur'
          },
          {
            pattern: /^[^\u4e00-\u9fa5]+$/,
            message: this.$t('ctp.noChinese'),
            trigger: 'blur'
          }
        ]
      },
      empty: false,
      notSupportTargetLangLength: 0,
      notSupportLangId: '',
      targetLanguageId: '',
      targetLanguage: '',
      subBtnDisabled: false,
      saveSpId: '', // 以保存的spid
      minExpectDay: '',
      iScountryCode: false, // 是否站点一致
      spTeamId: '',
      zoneIdData: '',
      countryCode: sessionStorage.getItem('agc_team_countryCode'),
      teamSiteId: sessionStorage.getItem('agc_team_siteId'),
      userType: sessionStorage.getItem('agc_userType'),
      saveOrder: false,
      backFlag: false,
      payMethod: null,
      qrValue: '',
      size: 150,
      qrCodeExpirationTime: 0,
      ordertime: '',
      qcTimeTags: {
        highlight: {
          style: {
            color: '#B92E29'
          }
        }
      },
      refreshTags: {
        refresh: {
          click: () => {
            this.clearTimer();
            this.queryQrcode();
          }
        },
        viewbalance: {
          href: this.getViewBalanceUrl(MENUIDOBJ),
          target: '_blank'
        }
      },
      timerCount: null,
      timerQuery: null,
      payTip: this.$t('ctp.serviceMarkets.payTips'),
      payTipTags: PAYGUIDE_TAGS,
      intervalTime: 2000,
      qRLoading: false,
      payBtnDisabled: false,
      currency: '',
      linkUrl: RECHARGELINK,
      currencyTipsShow: false,
      helpLink: HELPLINK,
      serviceName: '',
      // 当前语种
      language: '',
      // 代金券集合
      vouchersList: [],
      // 满减券集合
      couponsList: [],
      // 不可用满减券
      unAvailableCouponList: [],
      // 可用满减券
      availableCouponList: [],
      couponsVisible: false,
      dialogVisible: false,
      // 调用保存接口的参数
      saveBody: '',
      // 支付弹窗显示价格
      totalPrice: 0.00
    };
  },
  computed: {
    minutesCount() {
      const minutes = String(Math.floor(this.qrCodeExpirationTime / 60));
      if (minutes.length === 1) {
        return `0${minutes}`;
      } else {
        return minutes;
      }
    },
    secondsCount() {
      const second = String(this.qrCodeExpirationTime % 60);
      if (second.length === 1) {
        return `0${second}`;
      } else {
        return second;
      }
    },
    couponsMoney() {
      return this.availableCouponList.find(item =>
        item.checked === true
      ) || { remainingAmount: '0.00' };
    },
    vouchersMoney() {
      let amount = 0;
      this.vouchersList.map(item => {
        if (item.checked) {
          amount = floatAdd(parseFloat(amount), item.remainingAmount);
        }
      });
      if (this.supplier.order - this.supplier.discount - this.couponsMoney.remainingAmount < 0) {
        amount = 0;
      } else if (amount > this.supplier.order - this.supplier.discount - this.couponsMoney.remainingAmount) {
        amount = floatSub(floatSub(
          floatToFixed(this.supplier.order),
          floatToFixed(this.supplier.discount),
        ), floatToFixed(this.couponsMoney.remainingAmount));
      }
      return floatToFixed(amount);
    }
  },
  watch: {
    payMethod() {
      this.balancePay();
    },
    'couponsMoney.remainingAmount': function() {
      this.supplier.total = floatSub(floatSub(floatSub(
        floatToFixed(this.supplier.order),
        floatToFixed(this.supplier.discount),
      ), floatToFixed(this.couponsMoney.remainingAmount)), floatToFixed(this.vouchersMoney));
      this.totalPrice = this.supplier.total;
    },
    vouchersMoney() {
      this.supplier.total = floatSub(floatSub(floatSub(
        floatToFixed(this.supplier.order),
        floatToFixed(this.supplier.discount),
      ), floatToFixed(this.couponsMoney.remainingAmount)), floatToFixed(this.vouchersMoney));
      this.totalPrice = this.supplier.total;
    }
  },
  async mounted() {
    this.zoneIdData = sessionStorage.getItem('tszoneId');
    sessionStorage.setItem('savePromptFlag', 'Y');
    // 添加监听事件
    window.addEventListener('message', this.handleSaveConfirmNew);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('message', this.handleSaveConfirmNew);
    });
    this.language = await AGC.getLanguage().language;
    await this.getIntervalTime();
    await this.queryCurrency();
  },
  destroyed() {
    this.clearTimer();
  },
  methods: {
    selectVoucher(selectedIndex) {
      this.vouchersList.forEach((item, index) => {
        if (selectedIndex === index) {
          item.checked = !item.checked;
        }
      });
      this.vouchersList = [...this.vouchersList];
    },
    selectCoupon(selectedIndex) {
      this.availableCouponList.forEach((item, index) => {
        if (selectedIndex === index) {
          item.checked = !item.checked;
        } else {
          item.checked = false;
        }
      });
      this.availableCouponList = [...this.availableCouponList];
    },
    openVouchersDialog() {
      if (this.vouchersList.length > 0) {
        this.dialogVisible = true;
      }
    },
    openCouponsDialog() {
      if (this.availableCouponList.length > 0) {
        this.couponsVisible = true;
      }
    },
    getCoupons() {
      const body = `?language=${LANGOBJ[this.language]}&type=0&pageSize=100&pageIndex=1&serviceNames=${this.serviceName}`;
      const url = `ass/subscribe-service/v1/activities/user${body}`;
      AGC.callServiceAPI(
        url,
        {},
        'get',
        this.zoneIdData,
        3000,
        res => {
          if (res.ret && res.ret.code === 0) {
            const userActivities = res.userMarketingActivities;
            this.vouchersList = userActivities.filter(item => item.goodsType === VOUCHER_TYPE);
            this.couponsList = userActivities.filter(item => item.goodsType === COUPON_TYPE);
            this.handleVouchersList();
            this.handleCouponList();
            return;
          }
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
        },
        err => {
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
          return err;
        }
      );
    },

    handleVouchersList() {
      const timeNow = moment().format('YYYYMMDDHHmmss');
      this.vouchersList = this.vouchersList.filter(
        item => item.remainingAmount > 0 && parseFloat(item.expireTime) > timeNow && item.status !== 0
      );
      // 已使用代金券
      this.supplier.rewardInfos = this.supplier.rewardInfos || [];
      const usVouchersList = this.supplier.rewardInfos.filter(item =>
        item.type === VOUCHER_TYPE
      );
      this.vouchersList.forEach(item => {
        item.expireDate = moment(item.expireTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
        item.checked = usVouchersList.some(val => val.planId === item.planId);
      });
    },
    handleCouponList() {
      const timeNow = moment().format('YYYYMMDDHHmmss');
      this.couponsList = this.couponsList.filter(item => parseFloat(item.expireTime) > timeNow && item.status === 1);
      this.couponsList.forEach(item => {
        item.expireDate = moment(item.expireTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
        item.remainingAmount = floatToFixed(item.remainingAmount);
      });
      // 不可用满减券
      this.unAvailableCouponList = this.couponsList.filter(item => item.minimumAmount > floatSub(floatToFixed(this.supplier.order),
                                                                                                 floatToFixed(this.supplier.discount)));
      // 可用满减券
      this.availableCouponList = this.couponsList.filter(item => item.minimumAmount <= floatSub(floatToFixed(this.supplier.order),
                                                                                                floatToFixed(this.supplier.discount)));
      // 已用满减券和未用区分
      this.availableCouponList.forEach(item => {
        item.checked = item.planId === this.supplier.rewardInfos.find(item => item.type === COUPON_TYPE).planId;
      });
    },
    hideCouponsDialog() {
      this.couponsVisible = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    getViewBalanceUrl() {
      const url =
        `${window.location.origin
        }/consumer/cn/service/josp/agc/index.html#/accountCenter/${this.getViewBalanceMenuId()}`;
      return url;
    },
    getViewBalanceMenuId() {
      let menuId = MENUIDOBJ['developerlf'];
      if (window.location.host.indexOf('developer.huawei.com') > -1) {
        menuId = MENUIDOBJ['developer'];
      }
      if (window.location.origin.indexOf('developerlfdev') > -1) {
        menuId = MENUIDOBJ['developerlfdev'];
      }
      return menuId;
    },
    // 支付或保存接口
    servicePayOrSave(body) {
      const url = 'translation/v1/cp/pay';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          20000,
          resolve,
          reject
        );
      });
    },
    // 试算接口
    servicePriceCalculation(body) {
      const url = 'translation/v1/cp/getServiceProvider';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          7000,
          resolve,
          reject
        );
      });
    },
    // 取消订单接口
    serviceTaskStatus(body) {
      const url = 'translation/v1/cp/task/status';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          15000,
          resolve,
          reject
        );
      });
    },
    codeCkech(res) {
      if (!res || !res.ret || res.ret.code !== 0) {
        return false;
      }
      return true;
    },
    changePayMethod(val) {
      if (val === 2) {
        this.queryQrcode();
      } else {
        this.clearTimer();
        /*
         * 取消接口
         * 保存接口
         * 试算接口
         */
        const cancelTaskBody = {
          taskId: this.taskId
        };
        const { supplierId, siteId } = this.saveBody;
        const priceBody = {
          spId: supplierId,
          taskId: this.taskId,
          siteId
        };
        this.saveBody.payChannel = val;
        this.serviceTaskStatus(cancelTaskBody).then(res => this.codeCkech(res) ? this.servicePayOrSave(this.saveBody) : Promise.reject())
          .then(res => {
            if (this.codeCkech(res)) {
              this.servicePriceCalculation(priceBody).then(res => {
                if (res && res.ret && !res.ret.code) {
                  this.totalPrice = floatToFixed(res.serviceProviders[0].total);
                }
              });
              return;
            }
            return Promise.reject();
          }).catch(err => {
            this.payMethod = 2;
            this.$promptMsg(
              `${this.$t('ctp.tips.payError.abnormal')}`,
              this.$t('ctp.tips')
            );
            return err;
          });
      }
    },
    handleChange() {
      if (!this.expectDeliveryDay) {
        this.expectDeliveryDay = 0;
      }
    },
    async getConfig() {
      await getConfigs('SP', 'SP.SLA')
        .then(res => {
          if (res.ret.code === 0) {
            this.expectDeliveryDayArr = res.data;
            this.getTask();
          } else {
            this.$promptMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.tips')
            );
          }
        })
        .catch(err => {
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
          return err;
        });
    },
    async getIntervalTime() {
      await getConfigs('CP', 'TranslationReq.Interval').then(res => {
        if (res.ret.code === 0) {
          this.intervalTime = res.data[0].ruleValue
            ? Number(res.data[0].ruleValue)
            : 2000;
        }
      });
    },
    SwitchSupplier(index) {
      this.empty = false;
      this.loading = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      const { spId, serviceName } = this.supplierListOne[index];
      this.serviceName = serviceName;
      this.getTeamInfo(this.supplierListOne[index].spCountryCode);
      this.expectDeliveryDayArr.forEach(el => {
        if (el.userId === spId) {
          this.expectDeliveryDay = el.ruleValue;
          this.minExpectDay = Number(el.ruleValue);
        }
      });
      this.timeout = setTimeout(async() => {
        await this.getSupplierOne(spId);
      }, 1000);
    },
    handleSaveConfirmNew(e) {
      const dataNew = e.data;
      if (dataNew.command !== 'save') {
        return;
      }
      if (dataNew.command === 'save' && dataNew.clickInfo.updateCatalog) {
        window.top.postMessage(
          {
            functionName: 'handleIframeSaveMessage',
            params: [dataNew.clickInfo]
          },
          window.top.location.origin
        );
      } else if (
        dataNew.command === 'save' &&
        !dataNew.clickInfo.updateCatalog
      ) {
        this.$confirm(
          this.$t('ctp.tips.confirmSaveOrder'),
          this.$t('ctp.saveOrder'),
          {
            callback: action => {
              if (action === 'confirm') {
                this.$refs['Form'].validate(valid => {
                  if (valid) {
                    this.save00 = true;
                    this.conformDialogVisible = false;
                    this.noRepClick = false;
                    const data = {};
                    data.expectDeliveryDay = this.expectDeliveryDay;
                    data.save00 = this.save00;
                    data.devEmail = this.Form.devEmail;
                    data.supplierId = this.supplier.spId;
                    data.isAgreePrivacy = this.agreePrivacy;
                    data.remarksForSp = this.remarksForSp;
                    data.spuid = this.supplier.spUid;
                    data.supplierName = this.supplier.spName;
                    data.agreementUrl = this.supplier.agreementUrl;
                    data.status = '';
                    data.notSupportLangId = this.notSupportLangId;
                    data.payChannel = this.payMethod;
                    data.siteId = this.teamSiteId;
                    const { taskId } = this;
                    if (taskId) {
                      data.taskId = taskId;
                    }
                    /** 设置文件元数据 */
                    const md5TeamId = this.$agc.md5(data.spuid);
                    this.updateUploadFileMetedata(
                      md5TeamId,
                      this.result.sourceFilePath
                    );
                    // 调用支付函数。。。。
                    const header = {};
                    this.savingDialogVisible = true;
                    const url = 'translation/v1/cp/pay';
                    AGC.callServiceAPI(
                      url,
                      { header, body: data },
                      'POST',
                      this.zoneIdData,
                      20000,
                      res => {
                        this.noRepClick = true;
                        if (res.ret.code === 0) {
                          this.savingDialogVisible = false;
                          sessionStorage.setItem('savePromptFlag', 'N');
                          window.top.postMessage(
                            {
                              functionName: 'handleIframeSaveMessage',
                              params: [dataNew.clickInfo]
                            },
                            window.top.location.origin
                          );
                        } else if (res.ret.code === 1) {
                          this.$promptMsg(
                            this.$t('ctp.error.saveFailed'),
                            this.$t('ctp.tips')
                          );
                          this.save00 = false;
                        }
                      },
                      err => {
                        this.savingDialogVisible = false;
                        this.noRepClick = true;
                        this.$promptMsg(
                          this.$t('ctp.error.saveFailed'),
                          this.$t('ctp.tips')
                        );
                        this.save00 = false;
                        sessionStorage.setItem('savePromptFlag', 'N');
                        window.top.postMessage(
                          {
                            functionName: 'handleIframeSaveMessage',
                            params: [dataNew.clickInfo]
                          },
                          window.top.location.origin
                        );
                        return err;
                      }
                    );
                  }
                });
              }
              if (action === 'cancel') {
                sessionStorage.setItem('savePromptFlag', 'N');
                window.top.postMessage(
                  {
                    functionName: 'handleIframeSaveMessage',
                    params: [dataNew.clickInfo]
                  },
                  window.top.location.origin
                );
              }
            }
          }
        );
      }
    },
    async queryCurrency() {
      const header = {};
      const body = {};
      const url = 'ass/subscribe-service/v1/accountInfo/currency';
      await AGC.callServiceAPI(
        url,
        { body, header },
        'GET',
        this.zoneIdData,
        3000,
        res => {
          this.currency = res.currency;
          this.getConfig();
        }
      );
    },
    saves(Form, back) {
      if (this.Form.devEmail) {
        this.$refs[Form].validate(valid => {
          if (valid) {
            this.saveOrder = true;
            if (back) {
              this.backFlag = true;
            }
          } else {
            setTimeout(() => {
              const isError = document.getElementsByClassName('is-error');
              isError[0].querySelector('input').focus();
              this.backFlag = false;
            }, 100);
            return false;
          }
        });
      } else {
        this.saveConfirm();
        if (back) {
          this.backFlag = true;
        }
      }
    },
    saveConfirm() {
      this.dotting('翻译服务CP--保存');
      this.save00 = true;
      setTimeout(() => this.pay(), 500);
    },
    cancel() {
      interactiveHaLog({
        title: '翻译服务CP--上一步',
        tagType: 'TranslationService',
        translationServiceId: ''
      });
      sessionStorage.setItem('savePromptFlag', 'N');
      this.$router.push({ path: '/cp/create' });
    },
    // 取消支付
    cancelPayment() {
      this.conformDialogVisible = false;
      if (this.noRepClick) {
        this.clearTimer();
        sessionStorage.setItem('savePromptFlag', 'N');
        /** 设置文件元数据 */
        const md5TeamId = this.$agc.md5(this.supplier.spUid.toString());
        this.updateUploadFileMetedata(md5TeamId, this.result.sourceFilePath);
        this.$router.push({ path: '/cp/list' });
      }
    },
    getSupplierOne(spId) {
      const taskId = sessionStorage.getItem('agc_translate_skip_taskId');
      const body = {};
      body.taskId = taskId;
      body.spId = spId;
      switch (this.language) {
      case 'cn':
        body.language = 'zh_CN';
        break;
      case 'en':
        body.language = 'en_US';
        break;
      case 'ru':
        body.language = 'ru_RU';
      }
      body.siteId = this.teamSiteId;
      const url = 'translation/v1/cp/getServiceProvider';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        15000,
        async res => {
          if (res.ret.code === 0 && res.serviceProviders.length > 0) {
            this.loading = false;
            this.supplier = res.serviceProviders[0];
            this.supplier.lang = this.supplier.lang.sort((a, b) =>
              a.targetLanguageName.localeCompare(
                b.targetLanguageName,
                'zh-Hans-CN',
                { sensitivity: 'accent' }
              )
            );
            this.supplierList = res.serviceProviders;
            this.concatArr(
              this.targetLanguageId.split(','),
              this.targetLanguage
            );
            this.totalPrice = floatToFixed(this.supplier.total);
            await this.getCoupons();
          } else {
            this.$alertMsg(
              this.$t('ctp.error.noProvider'),
              this.$t('ctp.warning')
            );
            this.loading = false;
            this.empty = true;
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.searchProviderFail'),
            this.$t('ctp.warning')
          );
          this.loading = false;
          this.empty = true;
          return err;
        }
      );
    },
    getTask() {
      const taskId = sessionStorage.getItem('agc_translate_skip_taskId');
      const data = {};
      if (taskId && taskId !== undefined) {
        this.taskId = taskId;
      }
      data.query = 'query';
      data.taskId = taskId;
      const url = 'translation/v1/cp/orderAndTask';
      AGC.callServiceAPI(
        url,
        { header: {}, body: data },
        'POST',
        this.zoneIdData,
        6000,
        res00 => {
          if (res00.ret.code === 0) {
            const res = res00.data;
            this.targetLanguageId = res.targetLanguageId;
            this.targetLanguage = res.targetLanguage;
            this.expectDeliveryDay = res.expectDeliveryDay;
            this.remarksForSp = res.remarksForSp ? res.remarksForSp : '';
            this.Form.devEmail = res.devEmail ? res.devEmail : '';
            this.application = res.application;
            this.payMethod = res.payChannel ? res.payChannel : null;
            if (this.userType === '1') {
              this.payMethod = 2;
            }
            this.result = res;
            if (
              !res.applicationName &&
              !res.appIntroduce &&
              !res.appIntroduceInshort
            ) {
              this.result.applicationName = '';
              this.result.appIntroduce = '';
              this.result.appIntroduceInshort = '';
            }
            if (!this.result.appFeatures) {
              this.result.appFeatures = '';
            }
            if (!res.sourceFileName) {
              this.result.sourceFileName = '';
            }
            this.saveSpId = res.spId ? res.spId : ''; // spid
            const appFeatures = this.result.appFeatures
              ? `-${this.result.appFeatures}`
              : '';
            this.showTitle = `${this.result.applicationName}-
              ${this.result.appIntroduce}-${this.result.appIntroduceInshort} 
              ${appFeatures}`;
            this.getSupplier();
          }
        },
        err => {
          this.loading = false;
          this.empty = true;
          return err;
        }
      );
    },
    // 获取供应商
    getSupplier() {
      const header = {};
      const taskId = sessionStorage.getItem('agc_translate_skip_taskId');
      const body = { spId: '', taskId: '' };
      if (taskId && taskId !== undefined) {
        body.taskId = taskId;
      }
      const url = 'translation/v1/cp/getServiceProvider';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        7000,
        async res => {
          if (res.serviceProviders && res.serviceProviders.length > 0) {
            res.serviceProviders.forEach(e => {
              let spNameValue = '';
              e.languages.forEach(el => {
                if (this.language === 'cn' && el.language === 'zh_CN') {
                  spNameValue = el.spName;
                } else if (this.language === 'en' && el.language === 'en_US') {
                  spNameValue = el.spName;
                } else if (this.language === 'ru' && el.language === 'ru_RU') {
                  spNameValue = el.spName;
                } else if (el.language === 'en_US') {
                  spNameValue = el.spName;
                }
              });
              this.supplierListOne.push({
                spId: e.spId,
                spNameValue,
                webSite: e.webSite,
                spCountryCode: e.countryCode,
                serviceName: e.serviceName
              });
            });
            let acquiredspId = '';
            for (let i = 0; i < this.supplierListOne.length; i++) {
              if (
                this.saveSpId &&
                this.supplierListOne[i].spId === this.saveSpId
              ) {
                acquiredspId = this.saveSpId;
                this.serviceName = this.supplierListOne[i].serviceName;
                this.getTeamInfo(this.supplierListOne[i].spCountryCode);
                break;
              } else {
                this.serviceName = this.supplierListOne[0].serviceName;
                acquiredspId = this.supplierListOne[0].spId;
                this.getTeamInfo(this.supplierListOne[0].spCountryCode);
              }
            }
            this.expectDeliveryDayArr.forEach(el => {
              if (el.userId === acquiredspId) {
                if (!this.expectDeliveryDay) {
                  this.expectDeliveryDay = el.ruleValue;
                }
                this.minExpectDay = Number(el.ruleValue);
              }
            });
            body.spId = acquiredspId;
            switch (this.language) {
            case 'cn':
              body.language = 'zh_CN';
              break;
            case 'en':
              body.language = 'en_US';
              break;
            case 'ru':
              body.language = 'ru_RU';
            }
            body.siteId = this.teamSiteId;
            const url = 'translation/v1/cp/getServiceProvider';
            AGC.callServiceAPI(
              url,
              { header, body },
              'POST',
              this.zoneIdData,
              15000,
              async res => {
                if (res.ret.code === 0) {
                  if (taskId && taskId !== undefined) {
                    this.supplierList = res.serviceProviders;
                    this.concatArr(
                      this.targetLanguageId.split(','),
                      this.targetLanguage
                    );
                    this.supplierList.forEach(el => {
                      if (el.spId === acquiredspId) {
                        this.supplier = el;
                      }
                    });
                    this.supplier.lang = this.supplier.lang.sort((a, b) =>
                      a.targetLanguageName.localeCompare(
                        b.targetLanguageName,
                        'zh-Hans-CN',
                        { sensitivity: 'accent' }
                      )
                    );
                    this.totalPrice = floatToFixed(this.supplier.total);
                    await this.getCoupons();
                    this.loading = false;
                  }
                } else if (
                  res.ret.code === 1 &&
                  res.ret.msg === 'get currency fail'
                ) {
                  this.loading = false;
                  this.empty = true;
                } else {
                  this.loading = false;
                  this.empty = true;
                  this.$promptMsg(
                    `${this.$t('ctp.tips.payError.abnormal')}(${
                      res.errorCode ? res.errorCode : res.ret.code
                    })`,
                    this.$t('ctp.tips')
                  );
                }
              },
              err => {
                this.loading = false;
                this.empty = true;
                return err;
              }
            );
          } else if (res.ret.code === 141940559636) {
            this.loading = false;
            this.empty = true;
            this.$promptMsg(
              this.$t('ctp.tips.noSupplier'),
              this.$t('ctp.tips')
            );
            sessionStorage.setItem('savePromptFlag', 'N');
            this.$router.push({ path: '/cp/create' });
          } else {
            this.loading = false;
            this.empty = true;
            this.$promptMsg(
              this.$t('ctp.error.searchProviderFail'),
              this.$t('ctp.tips')
            );
          }
        },
        err => {
          this.loading = false;
          this.empty = true;
          return err;
        }
      );
    },

    // 查询sp的站点信息
    getTeamInfo(spCountryCode) {
      switch (spCountryCode) {
      case 1:
        this.countryCode === 'CN'
          ? (this.iScountryCode = false)
          : (this.iScountryCode = true);
        break;
      case 5:
        this.countryCode === 'SG'
          ? (this.iScountryCode = false)
          : (this.iScountryCode = true);
        break;
      case 7:
        this.countryCode === 'DE'
          ? (this.iScountryCode = false)
          : (this.iScountryCode = true);
        break;
      case 8:
        this.countryCode === 'RU'
          ? (this.iScountryCode = false)
          : (this.iScountryCode = true);
        break;
      }
    },

    concatArr(arr1, langNames) {
      this.noTargetLan = {};
      const tarNames = langNames.split(',');
      this.supplierList.forEach(sp => {
        const { spId } = sp;
        const targetIdIndexArr = [];
        const len = arr1.length;
        for (let i = 0; i < len; i++) {
          sp.lang.forEach(lang => {
            if (arr1[i] === lang.targetLanguageId) {
              targetIdIndexArr.push(i);
            }
          });
        }
        const notSupportTargetLanguages = [];
        // 不支持语言数组
        const notSupportTargetLanguagesID = [];
        for (let i = 0; i < len; i++) {
          if (!targetIdIndexArr.includes(i)) {
            notSupportTargetLanguages.push(
              tarNames[i] ? tarNames[i].split('-')[0] : ''
            );
            notSupportTargetLanguagesID.push(arr1[i] ? arr1[i] : '');
          }
        }
        this.notSupportLangId = notSupportTargetLanguagesID.join(',');
        this.notSupportTargetLangLength = notSupportTargetLanguages.length;
        if (notSupportTargetLanguages.length > 0) {
          this.$set(
            this.noTargetLan,
            spId,
            notSupportTargetLanguages.join(this.$t('ctp.comma'))
          );
        }
      });
    },
    infoVerification() {
      this.flag = false;
      if (!this.noRepClick && this.save00) {
        this.save00 = false;
        this.$alertMsg(this.$t('ctp.warn.noClickSave'), this.$t('ctp.warning'));
        return;
      }
      if (!this.noRepClick && !this.save00) {
        this.save00 = false;
        this.$alertMsg(this.$t('ctp.warn.noClickPay'), this.$t('ctp.warning'));
        return;
      }
      if (!this.supplier.spId) {
        this.save00 = false;
        this.$alertMsg(
          this.$t('ctp.warn.selectSupplier'),
          this.$t('ctp.warning')
        );
        return;
      }
      if (this.agreePrivacy !== true) {
        this.save00 = false;
        this.noAgree = true;
        return;
      } else {
        this.noAgree = false;
      }
      this.flag = true;
    },
    submitTask(Form) {
      this.$refs[Form].validate(valid => {
        if (valid) {
          this.dotting('翻译服务CP--提交');
          this.infoVerification();
          if (this.flag) {
            this.getOrderTime();
          }
        } else {
          setTimeout(() => {
            const isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 100);
          return false;
        }
      });
    },
    // 是否勾选隐私协议
    isAgree(val) {
      if (val) {
        this.noAgree = false;
      }
    },
    getOrderTime() {
      this.noRepClick = false;
      const data = {};
      const allCouponList = [...this.availableCouponList, ...this.vouchersList];
      if (allCouponList.length) {
        data.deductedRewardInfos = [];
        data.deductedRewardInfos = allCouponList.filter(item => item.checked).map(el => {
          const { planId, goodsId, goodsType } = el;
          return { planId, goodsId, type: goodsType };
        });
        data.serviceName = this.serviceName;
        data.language = LANGOBJ[this.language];
      }
      data.expectDeliveryDay = this.expectDeliveryDay;
      data.save00 = true;
      data.devEmail = this.Form.devEmail;
      data.supplierId = this.supplier.spId;
      data.isAgreePrivacy = this.agreePrivacy;
      data.remarksForSp = this.remarksForSp;
      data.spuid = this.supplier.spUid;
      data.supplierName = this.supplier.spName;
      data.agreementUrl = this.supplier.agreementUrl;
      data.status = 'nopay';
      data.notSupportLangId = this.notSupportLangId;
      data.payChannel = this.payMethod;
      data.siteId = this.teamSiteId;
      const { taskId } = this;
      if (taskId) {
        data.taskId = taskId;
      }
      this.saveBody = JSON.parse(JSON.stringify(data));
      const header = {};
      const url = 'translation/v1/cp/pay';
      AGC.callServiceAPI(
        url,
        { header, body: data },
        'POST',
        this.zoneIdData,
        20000,
        res => {
          this.noRepClick = true;
          if (res.ret.code === 0 && res.expirationTime.expirationTime) {
            this.ordertime = timestampToDate(res.expirationTime.expirationTime);
            if (this.payMethod === 2) {
              this.getIntervalTime();
              this.queryQrcode();
            } else {
              this.conformDialogVisible = true;
            }
          } else {
            this.ordertime = moment()
              .add(7, 'days')
              .format('YYYY-MM-DD HH:mm:ss');
            this.$promptMsg(
              this.$t('ctp.tips.submitOrderFail'),
              this.$t('ctp.tips')
            );
          }
        },
        err => {
          this.noRepClick = true;
          this.ordertime = moment()
            .add(7, 'days')
            .format('YYYY-MM-DD HH:mm:ss');
          this.$promptMsg(
            this.$t('ctp.tips.submitOrderFail'),
            this.$t('ctp.tips')
          );
          return err;
        }
      );
    },
    pay() {
      this.conformDialogVisible = false;
      this.saveOrder = false;
      this.noRepClick = false;
      const data = {};

      /*
       * 满减 availableCouponList
       * 代金 vouchersList
       */
      const allCouponList = [...this.availableCouponList, ...this.vouchersList];
      if (allCouponList.length > 0) {
        data.deductedRewardInfos = [];
        data.deductedRewardInfos = allCouponList.filter(item => item.checked).map(el => {
          const { planId, goodsId, goodsType } = el;
          return { planId, goodsId, type: goodsType };
        });
        data.serviceName = this.serviceName;
        data.language = LANGOBJ[this.language];
      }
      data.expectDeliveryDay = this.expectDeliveryDay;
      data.save00 = this.save00;
      data.devEmail = this.Form.devEmail;
      data.supplierId = this.supplier.spId;
      data.isAgreePrivacy = this.agreePrivacy;
      data.remarksForSp = this.remarksForSp;
      data.spuid = this.supplier.spUid;
      data.supplierName = this.supplier.spName;
      data.agreementUrl = this.supplier.agreementUrl;
      data.notSupportLangId = this.notSupportLangId;
      data.payChannel = this.payMethod;
      data.siteId = this.teamSiteId;

      const { taskId } = this;
      if (taskId) {
        data.taskId = taskId;
      }

      /** 设置文件元数据 */
      const md5TeamId = this.$agc.md5(data.spuid.toString());
      this.updateUploadFileMetedata(md5TeamId, this.result.sourceFilePath);
      // 调用支付函数。。。。
      const header = {};
      if (this.save00) {
        this.savingDialogVisible = true;
      } else {
        this.payingDialogVisible = true;
      }
      let returnFlag = true;
      const url = 'translation/v1/cp/pay';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { header, body: data },
          'POST',
          this.zoneIdData,
          20000,
          res => {
            this.noRepClick = true;
            this.payingDialogVisible = false;
            this.savingDialogVisible = false;
            if (res.ret.code === 0 && res.ret.msg === 'save success') {
              this.$promptMsg(
                this.$t('ctp.tips.saveSuccess'),
                this.$t('ctp.tips'),
                { callback: action => {
                  if (action) {
                    sessionStorage.setItem('savePromptFlag', 'N');
                    this.$router.push({ path: '/cp/list' });
                  }
                } }
              );
            } else if (res.ret.code === 0 && res.ret.msg === 'success') {
              this.dotting('翻译服务CP--支付');
              this.$promptMsg(
                this.$t('ctp.tips.paySuccess'),
                this.$t('ctp.tips')
              );
              sessionStorage.setItem('savePromptFlag', 'N');
              this.$router.push({ path: '/cp/list' });
            } else if (res.ret.code === 141940557591) {
              returnFlag = false;
              if (this.userId === this.teamId) {
                this.$refs.rechargeDialogView.handleOpenRecharge();
              } else {
                this.payTipsVisible = true;
              }
            } else {
              returnFlag = false;
              this.$promptMsg(
                `${this.$t('ctp.tips.payError.abnormal')}(${
                  res.errorCode ? res.errorCode : res.ret.code
                })`,
                this.$t('ctp.tips')
              );
            }
            if (returnFlag) {
              resolve({ status: 'success' });
            } else {
              reject({ status: 'error' });
            }
          },
          err => {
            returnFlag = false;
            this.payingDialogVisible = false;
            this.savingDialogVisible = false;
            this.noRepClick = true;
            if (this.save00) {
              this.$promptMsg(
                this.$t('ctp.error.saveFailed'),
                this.$t('ctp.tips')
              );
              this.save00 = false;
            } else {
              this.$promptMsg(
                this.$t('ctp.error.payError.overtime'),
                this.$t('ctp.tips')
              );
              this.subBtnDisabled = true;
              setTimeout(() => {
                this.subBtnDisabled = false;
              }, 10000);
            }
            if (returnFlag) {
              resolve({ status: 'success' });
            } else {
              reject({ status: 'error' });
            }
            return err;
          }
        );
      });
    },
    queryQrcode() {
      this.qRLoading = true;
      this.clearTimer();
      this.noRepClick = false;
      const data = {};

      /*
       * 满减 availableCouponList
       * 代金 vouchersList
       */
      const allCouponList = [...this.availableCouponList, ...this.vouchersList];
      if (allCouponList.length > 0) {
        data.deductedRewardInfos = [];
        data.deductedRewardInfos = allCouponList.filter(item => item.checked).map(el => {
          const { planId, goodsId, goodsType } = el;
          return { planId, goodsId, type: goodsType };
        });
        data.language = LANGOBJ[this.language];
        data.serviceName = this.serviceName;
      }
      data.expectDeliveryDay = this.expectDeliveryDay;
      data.devEmail = this.Form.devEmail;
      data.supplierId = this.supplier.spId;
      data.isAgreePrivacy = this.agreePrivacy;
      data.remarksForSp = this.remarksForSp;
      data.spuid = this.supplier.spUid;
      data.supplierName = this.supplier.spName;
      data.agreementUrl = this.supplier.agreementUrl;
      data.notSupportLangId = this.notSupportLangId;
      data.payChannel = this.payMethod;
      data.save00 = this.save00;
      data.siteId = this.teamSiteId;
      const { taskId } = this;
      if (taskId) {
        data.taskId = taskId;
      }

      /** 设置文件元数据 */
      const md5TeamId = this.$agc.md5(data.spuid.toString());
      this.updateUploadFileMetedata(md5TeamId, this.result.sourceFilePath);
      // 调用支付函数。。。。
      const header = {};
      const url = 'translation/v1/cp/pay';
      AGC.callServiceAPI(
        url,
        { header, body: data },
        'POST',
        this.zoneIdData,
        20000,
        res => {
          this.qRLoading = false;
          if (res.ret.code === 0) {
            this.noRepClick = true;
            this.conformDialogVisible = true;
            const { payCodeInfo } = res;
            if (!payCodeInfo && this.supplier.total <= 0) {
              this.dotting('翻译服务CP--支付');
              this.$promptMsg(
                this.$t('ctp.tips.paySuccess'),
                this.$t('ctp.tips')
              );
              sessionStorage.setItem('savePromptFlag', 'N');
              this.$router.push({ path: '/cp/list' });
              return;
            }
            this.qrValue = payCodeInfo.payCodeUrl;
            this.qrCodeExpirationTime = payCodeInfo.payCodeExpireTime
              ? payCodeInfo.payCodeExpireTime
              : 300;
            this.clearTimer();
            this.startTimerCount();
            this.startTimerQuery();
          } else {
            this.noRepClick = true;
            this.payMethod = 1;
            this.$alertMsg(
              this.$t('ctp.error.systemBusy'),
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.qRLoading = false;
          this.noRepClick = true;
          this.payMethod = 1;
          this.$alertMsg(
            this.$t('ctp.error.systemBusy'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    balancePay() {
      if (this.payMethod === 1 && this.currency.length <= 0) {
        this.subBtnDisabled = true;
        this.payBtnDisabled = true;
        this.currencyTipsShow = true;
      } else {
        this.subBtnDisabled = false;
        this.payBtnDisabled = false;
        this.currencyTipsShow = false;
      }
    },
    updateUploadFileMetedata(spUid, sourcePath) {
      if (sourcePath && sourcePath.length > 0) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const realPath =
          sourcePath.indexOf(':') > -1
            ? sourcePath.substring(sourcePath.indexOf(':') + 1)
            : sourcePath;

        storageRef
          .child(realPath)
          .updateFileMetadata({ customMetadata: {teamid: spUid} })
          .then()
          .catch();
      }
    },
    handleBack() {
      this.saveOrder = false;
      if (this.backFlag) {
        sessionStorage.setItem('savePromptFlag', 'N');
        this.$router.push({ path: '/cp/list' });
      }
    },
    queryPayResult() {
      const body = {};
      body.taskId = this.taskId;
      const url = 'translation/v1/cp/payResult';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          if (res.status === 0) {
            this.dotting('翻译服务CP--支付');
            this.$promptMsg(
              this.$t('ctp.tips.paySuccess'),
              this.$t('ctp.tips')
            );
            sessionStorage.setItem('savePromptFlag', 'N');
            this.$router.push({ path: '/cp/list' });
          }
        }
      );
    },
    startTimerCount() {
      this.timerCount = setInterval(() => {
        if (this.qrCodeExpirationTime > 0) {
          this.qrCodeExpirationTime--;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },
    startTimerQuery() {
      this.timerQuery = setInterval(this.queryPayResult, this.intervalTime);
    },
    clearTimer() {
      if (this.timerCount) {
        clearInterval(this.timerCount);
        this.timerCount = null;
      }
      if (this.timerQuery) {
        clearInterval(this.timerQuery);
        this.timerQuery = null;
      }
    },
    dotting(val) {
      const langList = [];
      this.supplier.lang.forEach(item => {
        langList.push(item.targetLanguageName);
      });
      interactiveHaLogLanguage({
        title: val,
        tagType: 'TranslationService',
        Data: {
          translationServiceId: sessionStorage.getItem(
            'agc_translate_skip_taskId'
          ),
          originLanguage: this.supplier.sourceLanguageName,
          targetLanguage: langList
        }
      });
    }
  }
};
</script>

<style scoped>
.couponsMoney-div{
  position: absolute;
  bottom: 70px;
  right: 10px;
}
.vouchersMoney-div{
  position: absolute;
  bottom: 41px;
  right: 10px;
}
.arrow-right{
  cursor: pointer;
}
.balance-span {
  display: inline-block;
  margin-left: 5px;
}
.supplierprivacy-div {
  margin-top: 36px;
  min-height: 50px;
}
.no-supplierprivacy-font {
  font-size: 12px;
  color: #fa2a2d;
  margin-left: 4px;
}
.authorize {
  color: #007dff;
  text-decoration: none;
}
.authorize:link {
  color: #007dff;
  text-decoration: none;
}
.authorize:visited {
  color: #007dff;
  text-decoration: none;
}
.authorize:hover {
  color: #3397ff;
  text-decoration: none;
}
.authorize:active {
  color: #3397ff;
  text-decoration: none;
}
.label-box {
  display: inline-block;
  width: 140px;
  text-align: right;
  line-height: 20px;
  word-break: break-word;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.day {
  display: inline-block;
  margin-left: 20px;
}
.input-text {
  margin: 10px 0 0 163px !important;
}
.tr-main {
  vertical-align: middle !important;
}
.supplier-title {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.supplier-table {
  width: 100%;
  text-align: left;
}
.supplier-table thead tr {
  border-top: 1px solid #e2e2e2;
}
.supplier-back-grey {
  display: inline-block;
  padding: 4px 24px;
  font-size: 14px;
  background-color: #999;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 8px;
}
.supplier-midule-button {
  display: inline-block;
  width: 1%;
}
.supplier-email-address {
  width: 360px;
  outline: 0;
  border: 0;
  padding-left: 8px;
}
.supplier-div-item {
  margin-top: 36px;
  min-height: 85px;
}
.supplier-div-less-item {
  margin-top: 20px;
}
.supplier-appraise span {
  display: inline-block;
}

.supplier-table tbody tr:nth-child(2n + 1) {
  background-color: #f7f7f7;
}
.supplier-appraise-div {
  padding: 2px 0;
}
.supplier-mar-right {
  margin-right: 4px;
}
.supplier-up-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
}
.supplier-lang-table {
  border: 1px solid #e2e2e2;
  border-collapse: collapse;
  border-radius: 8px;
  margin-left: 163px;
  margin-top: -15px;
  width: 88%;
  text-align: center;
  position: relative;
}

.sensitive-tips {
  display: inline-block;
  width: 360px;
  line-height: 24px;
  background-color: antiquewhite;
  text-align: center;
  border-radius: 8px;
}
.sensitive-tips-link {
  color: blue;
  cursor: pointer;
}
.arrow-color {
  color: #5762ff;
}

.grey-notice {
  font-size: 12px;
  color: #000000;
  opacity: 100%;
}
.confirm-order-main {
  margin-top: 12px;
  padding: 28px 40px 32px 40px;
  min-height: 540px;
  background: #fff;
}
.remarks-content {
  display: inline-block;
  width: 98px;
  text-align: right;
}
.notice-before-save {
  margin-bottom: 4px;
  display: block;
}
.text-left {
  text-align: left;
}
tr.text-left td {
  border-top: 1px solid #e0e0e0;
  padding: 9px 0 9px 8px;
}
tr.text-left > td:not(:first-child)::before {
  content: "";
  height: 8px;
  border-right: 1px solid #e2e2e2;
  margin-right: 8px;
}
.supplier-title-font {
  font-size: 20px;
}
.zone-box {
  padding-left: 20px;
  margin: 8px 0;
  height: 20px;
}
tr.first-tr:nth-child(2n) {
  background-color: #f5f6f7;
}
tr.first-tr td {
  padding: 17px 0px 17px 20px;
}
.first-tr {
  text-align: left;
}
.gray-line {
  margin-top: 32px;
  height: 28px;
  border-top: 1px solid #e2e2e2;
}
.splitting-line {
  height: 12px;
  border-top: 1px solid #e2e2e2;
}
.splitting-line-div {
  font-size: 20px;
  margin-bottom: 36px;
  margin-top: 20px;
}
.title-font {
  font-size: 14px;
}
.second-tr {
  height: 50px;
}
.lable-color {
  color: red;
}
.title-weight {
  height: 50px;
  font-weight: 900;
}
.question-weight {
  font-weight: 400;
}
.devemail-input {
  width: 400px;
  color: #9e9e9e;
}
.textarea-style {
  width: 400px;
  height: 95px;
  color: #9e9e9e;
  vertical-align: top;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 400px;
}
.sort-caret {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  left: 7px;
}
.sort-caret.ascending {
  border-bottom-color: #c0c4cc;
  top: -13px;
}
.sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: -9px;
}
.ascending .sort-caret.ascending {
  border-bottom-color: #007dff;
}
.descending .sort-caret.descending {
  border-top-color: #007dff;
}
.caret-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
.el-link.el-link--default {
  color: #999;
}
.el-message-box__message {
  margin: 0;
  text-align: center;
  font-size: 16px;
}
.paying-dialog {
  font-size: 16px;
  text-align: center;
}
.paying-dialog-timer {
  color: #666;
  font-size: 14px;
  text-align: center;
}
.supplier-lang-table tr td {
  padding: 8px 0 8px 12px;
  border: 1px solid #e2e2e2;
  padding-left: 0px;
  padding-right: 0px;
}
.expect-time {
  margin-left: 20px;
}
.noTargetLan {
  height: 52px;
  color: #666666;
  text-align: left !important;
  word-break: break-all;
  white-space: pre-wrap;
}
.noTargetLan .noTarget {
  padding-left: 10px;
}
.priceTable {
  background-color: #f7f7f7;
  height: 117px;
}
.priceTable-div {
  float: right;
  line-height: 28px;
  text-align: right;
  padding-right: 10px;
}
.priceTable-div-number {
  float: right;
  line-height: 28px;
  text-align: right;
  padding-right: 30px;
}
.totalPrice {
  color: #fa2a2d;
  font-size: 20px;
}
.pay-ul {
  list-style-type: none;
  text-align: center;
  padding: 0;
}
.pay-ul li {
  line-height: 20px;
  margin-bottom: 20px;
}
.taskReplyList-div {
  line-height: 20px;
  margin-bottom: 4px;
}
.taskReplyList {
  display: inline-block;
}
.maxWidthSty {
  max-width: 400px;
}

.pay-money-span {
  margin-right: 20px;
  width: 180px;
  text-align: right;
  vertical-align: top;
}
.pay-dialog {
  text-align: center;
}

.el-input-number {
  width: 152px;
}
.pay-method {
  margin-left: 20px;
}
.qrcode-vue {
  text-align: center;
}
.pay-tips {
  margin-top: 15px;
  font-weight: 600;
  font-size: 18px;
  color: #191919;
}
.pay-money {
  font-size: 16px;
  font-weight: 600;
  color: #191919;
  text-align: center;
}
.qr-time {
  width: 150px;
  display: inline-block;
}
#downloadQr {
  display: inline-block;
  padding: 20px 15px;
  background-color: #f7f7f7;
  width: 250px;
}
.qr-bottom {
  margin-bottom: 15px;
}
.pay-for {
  margin-right: 20px;
}
.pay-later {
  margin-left: 0 !important;
}
.total-color {
  font-size: 16px;
  font-weight: 600;
  color: #b92e29;
  text-align: center;
}
.order-time {
  padding: 10px 16px;
  background-color: #f7f7f7;
  word-break: break-word;
}
.currencyTipsShow {
  margin: 8px 0 0 70px;
  color: #b92e29;
}
.currency-tips {
  color: #b92e29;
}
.notclick{
  cursor: not-allowed;
}
</style>

<style>
.tooltip-title {
  overflow-y: auto;
  max-width: 30%;
  max-height: 30%;
}
.el-input-number .el-input-number__decrease {
  border-radius: 8px 0 0 8px !important;
  background-color: #f2f2f2;
}
.el-input-number .el-input-number__increase {
  border-radius: 0 8px 8px 0 !important;
  background-color: #f2f2f2;
}
.supplier-div-item .el-form-item__error {
  margin-left: 160px !important;
}
.priceLabel > .agc-icon {
  margin-left: 5px;
}
.save-order {
  margin-top: 40px;
  text-align: center;
}
</style>
